import { faInfo, faUser } from "@fortawesome/free-solid-svg-icons";
import { getEmailLogLoading, getEmailLogs } from "./maintenance";
import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { dateFormatter } from "../../components/DateFormatter";
import { fetchEmailLog } from "./maintenanceSlice";
import { useHistory } from "react-router-dom";

const MaintenanceEmailLogs = () => {
  const dispatch = useAppDispatch();
  const emailLogs = useAppSelector(getEmailLogs);
  const loading = useAppSelector(getEmailLogLoading);
  const history = useHistory();

  function accountClick(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.preventDefault();

    if (e.type === "auxclick") {
      window.open(
        `/user-account?userId=${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      return history.push({
        pathname: "/user-account",
        search: `?userId=${id}`,
      });
    }
  }

  useEffect(() => {
    dispatch(fetchEmailLog());
  }, [dispatch]);

  return (
    <>
      {loading === "pending" ? (
        <div>Loading...</div>
      ) : (
        <div className="table-responsive theme-overflow theme-overflow-table  small">
          <table className="table table-striped ">
            <thead className="sticky-top bg-dark">
              <tr>
                {/* <th>User ID</th> */}
                <th>Agent</th>
                <th>Date Sent</th>
                <th>Message</th>
                <th style={{ width: "8rem" }}></th>
              </tr>
            </thead>
            <tbody>
              {emailLogs?.map((log) => (
                <React.Fragment key={log.id}>
                  <tr className="theme-hover-row">
                    <th className="text-nowrap">{log.sender}</th>
                    <td className="text-nowrap">
                      {dateFormatter(log.dateSent)}
                    </td>
                    <td className="text-truncate">
                      {log.plainTextContent.substring(0, 80)}
                    </td>
                    <td className="align-middle">
                      {/* User Click through */}
                      <button
                        className="btn btn-sm btn-secondary float-end text-body py-0 border align-middle me-2"
                        onClick={(e) => accountClick(e, log.id)}
                        onAuxClick={(e) => accountClick(e, log.id)}
                      >
                        <FontAwesomeIcon icon={faUser} fixedWidth />
                      </button>

                      {/* Info button */}
                      <button
                        className={`btn btn-sm btn-primary text-body py-0 me-3 ${
                          log.plainTextContent ? "" : "theme-visually-hidden"
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${log.id}`}
                      >
                        <FontAwesomeIcon icon={faInfo} fixedWidth />
                      </button>
                    </td>
                  </tr>
                  {/* Email Message Full */}
                  <tr>
                    <td colSpan={4} className="p-0 border-0">
                      <div className="collapse" id={`collapse-${log.id}`}>
                        <div className="p-3 theme-white-space border-bottom">
                          {/* //in bracket ({}) so the newline characters are not
                          interpreted as plain text: */}
                          {/* {"foo\n bar"} */}

                          <iframe
                            title={log.id}
                            className="w-100"
                            srcDoc={log.htmlContent}
                            width="100%"
                            height="100%"
                            onLoad={(e) => {
                              const iframe =
                                e.currentTarget as HTMLIFrameElement;

                              iframe.height =
                                iframe.contentWindow?.outerHeight.toString() ??
                                "0";
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default memo(MaintenanceEmailLogs);

import { ErrorTypes, UserFailureData } from "../service/api.dtos";

import LoadingOverlay from "../components/LoadingOverlay";
import { getIsRecycle } from "../store/common";
import { getNestFailuresState } from "../store/nestFailures";
import { areObjectsEqual as isInitialLoad } from "../utils";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Issues() {
  const { usersFailuresData, loading } = useSelector(getNestFailuresState);
  const isRecycle = useSelector(getIsRecycle);

  const history = useHistory();

  function rowClick(id: string) {
    return history.push({
      pathname: "/failures",
      search: `?id=${id}`,
    });
  }
  return (
    <>
      {/* Loading */}
      {(!isRecycle ||
        isInitialLoad(usersFailuresData, new UserFailureData())) &&
      loading === "pending" ? (
        <LoadingOverlay loading={loading} />
      ) : (
        <div className="h-100 position-relative mt-3">
          <div className="theme-overflow px-3  table-responsive">
            <table
              id="table-failuresIssues"
              className="table table-dark table-hover mb-0  small"
            >
              <thead className="sticky-top">
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Requested Time</th>
                  <th scope="col">Issue</th>
                  <th scope="col">Resolution</th>
                </tr>
              </thead>
              <tbody>
                {usersFailuresData?.failures &&
                  Array.from(
                    usersFailuresData.failures.map((userFailureData) => (
                      <tr
                        className="theme-pointer"
                        key={userFailureData.id}
                        onClick={() => rowClick(userFailureData.id)}
                      >
                        <th scope="row" className="text-nowrap">
                          {userFailureData.typeOfError
                            .toString()
                            .split(/(?=[A-Z])/)
                            .join(" ")}
                        </th>
                        <td className="text-nowrap">
                          {new Date(userFailureData.occurred).toLocaleString(
                            "en-GB"
                          )}
                        </td>
                        <td>
                          {userFailureData.typeOfError.toString() ===
                          ErrorTypes[ErrorTypes.UserDeletions] ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: userFailureData.reason,
                              }}
                            />
                          ) : (
                            <div className="theme-white-space">
                              {userFailureData.reason}
                            </div>
                          )}
                        </td>
                        <td>{userFailureData.signupResolution}</td>
                      </tr>
                    ))
                  ).reverse()}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

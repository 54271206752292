import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";

import activeUsersSlice from "./activeUsersSlice";
import allUsersSlice from "./allUsersSlice";
import azureLinksSlice from "./azureLinksSlice";
import commonSlice from "./commonSlice";
import { configureStore } from "@reduxjs/toolkit";
import maintenanceModeSlice from "../features/maintenance/maintenanceSlice";
import nestCountSlice from "./nestCountSlice";
import nestingOverviewSlice from "./nestingOverviewSlice";
import nestingTrafficSlice from "./nestingTrafficSlice";
import reportsSlice from "../features/reports/reportsSlice";
import searchUserSlice from "./searchUserSlice";
import teamSlice from "./teamSlice";
import toastMessagesSlice from "../features/toastMessages/toastMessagesSlice";
import usersFailuresSlice from "./usersFailuresSlice";

function is401(action: any) {
  if (action?.payload?.responseStatus?.errorCode === "401") {
    return true;
  }
  return false;
}

/**
 * test stop
 */
export const errorInterceptor: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (is401(action)) {
        const redirectUri =
          window.location.origin +
          window.location.pathname +
          window.location.search;
        // const redirectUri = window.location.href;
        //Redirect to login
        // this for redirecting
        // const redirect = `${
        //   process.env.REACT_APP_API_BASE_URL
        // }/auth/msgraph?continue=${encodeURIComponent(redirectUri)}`;
        // if (window.location.hash !== "#s=1") {
        //   window.location.href = redirect;
        // } else {
        //   window.location.hash = "";
        // }
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/msgraph?continue=${redirectUri}`;

        //Fix for the ServiceStack NotImplementedException - need further investigation
        // window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/msgraph`;
      } else {
        //Show Error here
        console.error("Error", action.payload);
      }
    }

    return next(action);
  };

const store = configureStore({
  reducer: {
    activeUserState: activeUsersSlice,
    azureLinks: azureLinksSlice,
    commonState: commonSlice,
    maintenanceState: maintenanceModeSlice,
    nestCountState: nestCountSlice,
    nestingOverviewState: nestingOverviewSlice,
    nestingTrafficState: nestingTrafficSlice,
    reports: reportsSlice,
    teams: teamSlice,
    toastMessages: toastMessagesSlice,
    userState: searchUserSlice,
    usersFailuresState: usersFailuresSlice,
    usersState: allUsersSlice,
  },
  // We are using classes from ServiceStack as types
  // This disables the "A non-serializable value detection"
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorInterceptor),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { faCircleNotch, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  fetchEmailLog,
  setEmailMaintenanceMessage,
  setMaintenanceEmailMessage,
  setMaintenanceEmailMessageModalVisibility,
} from "./maintenanceSlice";
import {
  getEmailMaintenanceMessageLoading,
  getMaintenanceEmailMessageModalVisibility,
} from "./maintenance";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";
import { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Main from "../../components/TypeBackground";
import { Modal } from "bootstrap";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { useAppType } from "../../hooks/useAppType";

export default function MaintenanceEmailMessageModal() {
  const dispatch = useAppDispatch();
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const appType = useAppType();

  const [responseMessage, setResponseMessage] = useState<string>("");

  const maintenanceEmailMessageModeLoading = useAppSelector(
    getEmailMaintenanceMessageLoading
  );

  const maintenanceEmailMessageModalVisibility = useAppSelector(
    getMaintenanceEmailMessageModalVisibility
  );

  const showModal = () => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getOrCreateInstance(modalElement, {});

    bsModal.show();
  };

  const hideModal = () => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);

    setResponseMessage("");
    bsModal?.hide();
  };

  useEffect(() => {
    const modalEle = modalRef.current;
    //catch external & cancel click
    modalEle.addEventListener("hidden.bs.modal", () => {
      dispatch(setMaintenanceEmailMessageModalVisibility(false));
    });

    if (maintenanceEmailMessageModalVisibility === true) {
      showModal();
    } else {
      hideModal();
    }

    return () => modalEle.removeEventListener("hidden.bs.modal", dispatch);
  }, [dispatch, maintenanceEmailMessageModalVisibility]);

  function handleSaveMaintenanceEmailMessage(): void {
    dispatch(setEmailMaintenanceMessage())
      .unwrap()
      // return type is lost
      .then((response) => {
        dispatch(setMaintenanceEmailMessage(""));
        dispatch(fetchEmailLog());
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "success",
            text: `Maintenance email message sent to ${response.emailsAttempted}`,
          })
        );

        setResponseMessage(response.emailsAttempted?.toString() || "");
      })
      .catch((error) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: `Maintenance email message failed to send. ${error}`,
          })
        );
      });
  }

  return (
    <div
      ref={modalRef}
      className="modal fade"
      id="maintenanceEmailMessageModal"
      tabIndex={-1}
      aria-labelledby="maintenanceMailMessageModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-dark">
          <Main>
            <div className="modal-header">
              <h5 className="modal-title" id="maintenanceModalLabel">
                Maintenance Email Message - {appType}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {responseMessage ? (
                <div className="alert alert-success" role="alert">
                  Maintenance email message sent to {responseMessage}
                </div>
              ) : (
                <>
                  Are you sure you want to send this email message to all users?
                </>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary fw-bold"
                data-bs-dismiss="modal"
                disabled={
                  maintenanceEmailMessageModeLoading === "pending" ||
                  !!responseMessage
                }
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary text-body fw-bold"
                onClick={handleSaveMaintenanceEmailMessage}
                disabled={
                  maintenanceEmailMessageModeLoading === "pending" ||
                  !!responseMessage
                }
              >
                {maintenanceEmailMessageModeLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
                ) : (
                  <>
                    Send&nbsp;
                    <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
                  </>
                )}
              </button>
            </div>
          </Main>
        </div>
      </div>
    </div>
  );
}

import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import {
  faCircleNotch,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getTeam, getTeamLoading } from "../store/team";
import { useAppDispatch, useAppSelector } from "../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "../components/LoadingOverlay";
import TeamInfo from "../features/teamAccount/TeamInfo";
import TeamStats from "../features/teamAccount/TeamStats";
import TeamTable from "../features/teamAccount/TeamTable";
import { addToastMessage } from "../features/toastMessages/toastMessagesSlice";
import { fetchTeamDetails } from "../store/teamSlice";
import { useHistory } from "react-router";
import { useQuery } from "../utils";

const TeamAccount: FunctionComponent = () => {
  //fetchTeamDetails
  const query = useQuery();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const team = useAppSelector(getTeam);
  const teamLoading = useAppSelector(getTeamLoading);

  const [searchInput, setSearchInput] = useState<string>(
    query.get("teamId") ?? team?.id ?? ""
  );

  function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    e && e.preventDefault();
    const trimSearch = searchInput.trim();
    setSearchInput(trimSearch);

    if (trimSearch) {
      dispatch(fetchTeamDetails(trimSearch))
        .unwrap()
        .catch((error) => {
          dispatch(
            addToastMessage({
              id: new Date().getTime().toString(),
              severity: "danger",
              text: `Error: ${error?.responseStatus?.message}`,
            })
          );
        });
    }
  }

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setSearchInput(value);
  }

  function clear() {
    setSearchInput("");
  }

  useEffect(() => {
    if (searchInput) {
      handleSubmit();
    }

    // Remove Dash lane elements
    setTimeout(() => {
      const test = document.querySelectorAll("[data-dashlanecreated]");

      test.forEach((e) => e.remove());
    }, 400);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let stringBuilder = searchInput
      ? `?teamId=${encodeURIComponent(searchInput)} `
      : "";

    return history.push({
      pathname: "/team-account",
      search: stringBuilder,
    });
  }, [history, searchInput]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="container-lg py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="p-3 d-flex align-items-center">
          <h4 className="text-nowrap text-capitalize">
            {team?.name ? team.name : "Team Account"}
          </h4>
          {/* Bar spacer */}
          <div className="rounded flex-fill mx-3 bg-secondary w-100 h-50 text-secondary">
            .
          </div>
          <form
            className="input-group input-group-sm"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {/* Search  */}
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchInput}
            />
            {/* Clear */}
            <button
              className="btn btn-secondary border bg-dark"
              type="button"
              onClick={clear}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
            {/* Search no button */}
            <button
              className="btn btn-secondary border bg-dark"
              id="button-accountSearch"
              type="submit"
            >
              {teamLoading === "pending" ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  fixedWidth
                  className="text-primary"
                />
              ) : (
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              )}
            </button>
          </form>
        </div>

        <div className="h-100 d-flex position-relative mx-3 mb-3">
          <div className="border rounded d-flex flex-column theme-overflow">
            {/* Loading */}
            {searchInput !== team?.id && teamLoading === "pending" ? (
              <LoadingOverlay loading={teamLoading} />
            ) : (
              <>
                {team ? (
                  <>
                    {/* Team Details */}

                    <div className="p-3">
                      <div className="row mb-2">
                        <div className="col-12 col-md-6 d-flex flex-column">
                          <TeamInfo />
                        </div>
                      </div>
                    </div>

                    {/* Stats */}
                    <TeamStats />
                    {/* Team Members */}
                    <TeamTable />
                  </>
                ) : (
                  <div className="theme-nothing-found  d-flex flex-column flex-grow-1 justify-content-center align-self-center">
                    {teamLoading === "failed" && "Nothing Found 😥"}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamAccount;

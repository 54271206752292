import {
  faCheck,
  faCircleNotch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getTeam, getTeamAllowanceLoading } from "../../store/team";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { setTeamAllowance } from "../../store/teamSlice";
import { useState } from "react";

export default function TeamInfo() {
  const dispatch = useAppDispatch();
  const team = useAppSelector(getTeam);
  const teamAllowanceLoading = useAppSelector(getTeamAllowanceLoading);
  const [allowanceState, setAllowanceState] = useState<number>(
    team?.allowedMembers || 0
  );

  function allowanceSubmit() {
    if (team === null || allowanceState === null) {
      return;
    } else {
      dispatch(
        setTeamAllowance({ teamId: team.id, newAllowance: allowanceState })
      )
        .unwrap()
        .catch((error) => {
          //revert state
          setAllowanceState(team.allowedMembers);

          dispatch(
            addToastMessage({
              id: new Date().getTime().toString(),
              severity: "danger",
              text: `Error: ${error?.responseStatus?.message}`,
            })
          );
        });
    }
  }
  return (
    <>
      <div className="row mt-auto">
        <label
          htmlFor="updateAllowance"
          className="col-sm-5 col-form-label col-form-label-sm fw-bold"
        >
          Allowed Members
        </label>
        <div className="col-sm-7">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text w-25 text-muted bg-dark">
              Members
            </span>
            <input
              disabled={teamAllowanceLoading === "pending"}
              type="number"
              className="form-control"
              id="updateAllowance"
              value={allowanceState || ""}
              onChange={(e) => setAllowanceState(parseInt(e.target.value, 10))}
            />
            <button
              disabled={teamAllowanceLoading === "pending"}
              className="btn btn-secondary border bg-dark"
              onClick={allowanceSubmit}
            >
              {teamAllowanceLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      <dl className="row justify-content-between">
        <dt className="col-sm-5">Can Invite</dt>
        <dd className="col-sm-7">
          {team?.canInvite ? (
            <FontAwesomeIcon icon={faCheck} className="me-2" fixedWidth />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="me-2" fixedWidth />
          )}
        </dd>
      </dl>
    </>
  );
}

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { exportUsers, fetchAllUsers } from "../store/allUsersSlice";
import {
  faCircleNotch,
  faFileDownload,
  faLock,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  getALLUsersLoading,
  getAllUsers,
  getExportUsersLoading,
} from "../store/allUser";
import { useAppDispatch, useAppSelector } from "../hooks/reduxTypedHooks";

import CheckBool from "../components/CheckBool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListHeader from "../components/ListHeaders";
import LoadingOverlay from "../components/LoadingOverlay";
import { TableHeader } from "../Sort";
import { UserBasics } from "../service/api.dtos";
import { toggleHeaderSort } from "../Sort";
import { useHistory } from "react-router-dom";

enum AllUsersSearchType {
  firstName = 1,
  surname = 2,
}

export default function Users() {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(getAllUsers);
  const exportUsersLoading = useAppSelector(getExportUsersLoading);
  const loading = useAppSelector(getALLUsersLoading);
  const history = useHistory();

  const [usersFetched, setUsersFetched] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchAllUsers(false))
      .unwrap()
      .then(() => {
        setUsersFetched(true);
      });
  }, [dispatch]);

  const initialSortHeaders = useMemo(
    () =>
      [
        {
          property: "firstName",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "First Name",
          width: 7,
        },

        {
          property: "surname",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Surname",
          width: 7,
        },

        {
          property: "locked",
          cssClass: "text-center",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Locked",

          tableCellWidth: "1%",
        },

        {
          property: "failedNests",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Nests X",
          tableCellWidth: "1%",
          toolTipText: "Nests that failed to complete",
        },

        {
          property: "failurePercentage",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Failure %",
          tableCellWidth: "1%",
          toolTipText: "Percentage of failed nests",
        },

        {
          property: "totalNests",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Nests",
          tableCellWidth: "1%",
        },
        {
          property: "hasActivePlan",
          cssClass: "text-center",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Active Plan",
        },
        {
          property: "planLastPayment",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Last Payment",
        },
        {
          property: "planValidUntil",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Plan Valid Until",
        },
        {
          property: "percentageOfPlanRemaining",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Plan Remaining",
        },

        {
          property: "deleteAfter",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Delete After",
        },
        {
          property: "pickedUpForDeletionDate",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Deletion Date",
          toolTipText: "Date user was picked up for deletion",
        },
        {
          property: "signUpTime",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Sign Up Date",
        },
        {
          property: "lastLoggedInUntil",
          cssClass: "text-end",
          sort: {
            active: false,
            order: "desc",
            sortable: true,
            sortedIndexes: [],
          },
          title: "Last logged in",
        },
        // Team
        {
          property: "teamName",
          sort: {
            active: false,
            order: "desc",
            sortable: false,
            sortedIndexes: [],
          },
          title: "Team",
          tableCellWidth: "1%",
        },
      ] as TableHeader[],
    []
  );

  const [sortedUsers, setSortedUsers] = useState<UserBasics[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const [tableHeaders, setTableHeaders] =
    useState<TableHeader[]>(initialSortHeaders);

  const sortingColumn = useMemo(
    () => tableHeaders.find((header) => header.sort.active),
    [tableHeaders]
  );

  const setHeaderSort = useCallback(
    (header: TableHeader) => {
      if (header.property.length > 0) {
        const allUsersFlattened = allUsers.flatMap((user) => {
          return (user = {
            ...user,
            ...Object.assign(user.allTimeNestingCounts),
          });
        });

        setTableHeaders(
          toggleHeaderSort(header, tableHeaders, allUsersFlattened, "id")
        );
      }
    },
    [allUsers, tableHeaders]
  );

  const [searchType, setSearchType] = useState<AllUsersSearchType>(
    AllUsersSearchType.firstName
  );

  useEffect(() => {
    typeof sortingColumn !== "undefined"
      ? setSortedUsers(
          sortingColumn.sort.sortedIndexes.map((index) => {
            return allUsers[index];
          })
        )
      : setSortedUsers(allUsers);
  }, [allUsers, sortingColumn]);

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setSearchInput(value);
  }

  function clear() {
    setSearchInput("");
  }

  function handleSearchType(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;

    setSearchType(parseInt(value, 10));
  }

  useEffect(() => {
    setSortedUsers(() =>
      allUsers.filter((f) =>
        f[AllUsersSearchType[searchType]]
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, allUsers, searchType]);

  function accountClick(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.preventDefault();

    if (e.type === "auxclick") {
      window.open(
        `/user-account?userId=${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      return history.push({
        pathname: "/user-account",
        search: `?userId=${id}`,
      });
    }
  }

  function teamClick(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.preventDefault();

    if (e.type === "auxclick") {
      window.open(
        `/team-account?teamId=${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      return history.push({
        pathname: "/team-account",
        search: `?teamId=${id}`,
      });
    }
  }

  return (
    <section className="container-fluid py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="px-3 pt-3 d-flex align-items-center">
          <h4>Users</h4>
          <div className="rounded flex-fill mx-3 bg-secondary w-100 h-50 text-secondary">
            .
          </div>

          <div className="input-group input-group-sm">
            {/* search type */}

            <select
              className="form-select  theme-users-search-types"
              onChange={handleSearchType}
              name="searchType"
              value={searchType}
            >
              <option value="1">First name</option>
              <option value="2">Surname</option>
            </select>

            {/* Search */}
            <input
              className="form-control"
              onChange={handleSearchInput}
              value={searchInput}
              placeholder="Search"
            />
            {/* Users amount */}
            <div className="input-group-text bg-dark">{sortedUsers.length}</div>

            {/* Export */}
            <button
              title="Export users"
              type="button"
              className="btn btn-secondary border bg-dark"
              onClick={(e) => {
                e.preventDefault();
                dispatch(exportUsers());
              }}
            >
              {exportUsersLoading === "pending" ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  fixedWidth
                  className="text-primary"
                />
              ) : (
                <FontAwesomeIcon icon={faFileDownload} fixedWidth />
              )}
            </button>
            {/* Clear */}
            <button
              title="Clear search"
              className="btn btn-secondary border bg-dark"
              type="button"
              onClick={clear}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
          </div>
        </div>

        <div className="card-body h-100 d-flex position-relative m-3">
          <div className="border rounded theme-overflow">
            <LoadingOverlay loading={loading} />
            {usersFetched && !sortedUsers?.length && (
              <h4 className="text-center p-5">No results</h4>
            )}
            {loading === "failed" && (
              <h4 className="text-center p-5">Failed to load users</h4>
            )}

            {/* Users */}
            {loading === "succeeded" && sortedUsers?.length > 0 && (
              <div className="table-responsive theme-overflow theme-overflow-table">
                <table className="table table-dark table-hover mb-0  small">
                  <thead className="sticky-top border-bottom">
                    <tr>
                      {tableHeaders.map((header, index) => (
                        <ListHeader
                          key={index}
                          header={header}
                          toggleHeaderSort={setHeaderSort}
                          canSort={allUsers.length > 1}
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedUsers.flatMap((user) => (
                      <tr key={user.id} className=" ">
                        {/* First Name */}
                        <td style={{ maxWidth: "200px" }}>
                          <div className="d-flex">
                            <button
                              className="theme-ellipsis btn btn-link text-decoration-none fw-bold btn-sm theme-btn-hover py-0"
                              onClick={(e) => accountClick(e, user.id)}
                              onAuxClick={(e) => accountClick(e, user.id)}
                            >
                              {user.firstName}
                            </button>
                          </div>
                        </td>
                        {/* Surname */}
                        <td style={{ maxWidth: "200px" }}>
                          {" "}
                          <div className="d-flex">
                            <button
                              className="theme-ellipsis btn btn-link text-decoration-none fw-bold btn-sm theme-btn-hover py-0"
                              onClick={(e) => accountClick(e, user.id)}
                              onAuxClick={(e) => accountClick(e, user.id)}
                            >
                              {user.surname}
                            </button>
                          </div>
                        </td>
                        {/* Locked */}
                        <td className="text-center">
                          {user.locked ? (
                            <FontAwesomeIcon icon={faLock} fixedWidth />
                          ) : (
                            <span className="opacity-50">--</span>
                          )}
                        </td>
                        {/* Failed Nests */}
                        <td className="text-end">
                          {user.allTimeNestingCounts.failedNests}
                        </td>
                        {/* Failure Percentage */}
                        <td className="text-end">
                          {user.allTimeNestingCounts.failurePercentage.toFixed(
                            2
                          )}
                          %
                        </td>
                        {/* Total Nests */}
                        <td className="text-end text-nowrap">
                          {user.allTimeNestingCounts.totalNests}
                        </td>
                        {/* Active Plan */}
                        <td className="text-center text-nowrap">
                          <CheckBool bool={user.hasActivePlan} />
                        </td>
                        {/* Last Payment */}
                        <td className="text-end text-nowrap">
                          {user.planLastPayment ? (
                            new Date(user.planLastPayment)?.toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }
                            )
                          ) : (
                            <div className="opacity-50 text-center">--</div>
                          )}
                        </td>
                        {/* Plan Valid Until */}
                        <td className="text-end text-nowrap">
                          {user.planValidUntil ? (
                            new Date(user.planValidUntil)?.toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }
                            )
                          ) : (
                            <div className="opacity-50 text-center">--</div>
                          )}
                        </td>
                        {/* Plan Remaining */}
                        <td className="text-end text-nowrap">
                          {user.percentageOfPlanRemaining.toString()}%
                        </td>

                        {/* Delete After */}
                        <td className="text-end text-nowrap">
                          {user.deleteAfter ? (
                            new Date(user.deleteAfter)?.toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }
                            )
                          ) : (
                            <div className="opacity-50 text-center">--</div>
                          )}
                        </td>

                        {/* Picked Up For Deletion */}
                        <td className="text-end text-nowrap">
                          {user.pickedUpForDeletionDate ? (
                            new Date(
                              user.pickedUpForDeletionDate
                            )?.toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            })
                          ) : (
                            <div className="opacity-50 text-center">--</div>
                          )}
                        </td>

                        {/* Sign Up Date */}
                        <td className="text-end text-nowrap">
                          {user.signUpTime ? (
                            new Date(user.signUpTime)?.toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }
                            )
                          ) : (
                            <div className="opacity-50 text-center">--</div>
                          )}
                        </td>
                        {/* Last Logged in */}
                        <td className="text-end text-nowrap">
                          {user.lastLoggedInUntil ? (
                            new Date(
                              user.lastLoggedInUntil
                            )?.toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            })
                          ) : (
                            <div className="opacity-50 text-center">--</div>
                          )}
                        </td>

                        {/* Team Name */}
                        <td
                          className="text-nowrap "
                          style={{ maxWidth: "240px" }}
                        >
                          <div className="d-flex">
                            <button
                              className="theme-ellipsis btn btn-link text-decoration-none fw-bold btn-sm theme-btn-hover py-0"
                              onClick={(e) => teamClick(e, user.teamId)}
                              onAuxClick={(e) => teamClick(e, user.teamId)}
                            >
                              {user.teamName}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/*  */}
        </div>
      </div>
    </section>
  );
}

import { TableHeader } from "../../Sort";

export function useTeamsHeaders() {
  const initialTeamsSortHeaders = [
    {
      property: "name",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Team",
      width: 7,
    },
  ] as TableHeader[];

  return initialTeamsSortHeaders;
}

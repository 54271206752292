import { FunctionComponent, useEffect, useState } from "react";
import { TrialAcceptOrDeny, TrialAction } from "../service/api.dtos";
import { useAppDispatch, useAppSelector } from "../hooks/reduxTypedHooks";

import LoadingOverlay from "../components/LoadingOverlay";
import { addToastMessage } from "../features/toastMessages/toastMessagesSlice";
import { getAcceptOrDenyTrialLoading } from "../store/searchUser";
import { trialAcceptOrDeny } from "../store/searchUserSlice";
import { useHistory } from "react-router";
import { useQuery } from "../utils";

const Trial: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  const acceptOrDenyTrialLoading = useAppSelector(getAcceptOrDenyTrialLoading);

  //maybe add  state args here on load - searchQueryArg
  const [searchInput] = useState<Partial<TrialAcceptOrDeny>>({
    teamId: query.get("teamId") ?? "",
    userId: query.get("userId") ?? "",
    action: TrialAction[query.get("action") ?? ""],
  });

  useEffect(() => {
    dispatch(trialAcceptOrDeny(searchInput))
      .unwrap()
      .then((res) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "success",
            text: "Great Success!",
          })
        );
        //go to user
        return history.push({
          pathname: "/user-account",
          search: `?userId=${searchInput.userId}`,
        });
      })
      .catch((error) => {
        if (error?.responseStatus?.errorCode === "401") {
          //Reloads
        } else {
          dispatch(
            addToastMessage({
              id: new Date().getTime().toString(),
              severity: "danger",
              text: error?.responseStatus?.message ?? "Failed",
            })
          );
        }
      });
  }, [dispatch, searchInput, history]);

  return (
    <>
      {acceptOrDenyTrialLoading === "pending" && (
        <div className="position-relative h-100">
          <LoadingOverlay loading={"pending"} />
        </div>
      )}
    </>
  );
};

export default Trial;

import {
  AnnouncementEmailsLogs,
  ChangeMaintenance,
  ClearMaintenanceMessage,
  EmailMaintenanceMessage,
  EmailMaintenanceResponse,
  FeatureSetting,
  GetEmailLog,
  GetMaintenance,
  GetMaintenanceLog,
  SetMaintenanceMessage,
} from "../../service/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../../store/commonTypes";
import { MaintenanceModeLog } from "./../../service/api.dtos";
import { RootState } from "../../store/store";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export type MaintenanceState = {
  clearMaintenanceMessageLoading: Loading;
  emailLog: AnnouncementEmailsLogs[];
  emailLogLoading: Loading;
  maintenanceEmailMessage: string;
  maintenanceEmailMessageLoading: Loading;
  maintenanceEmailMessageModalVisibility: boolean;
  maintenanceEmailMessageResponse: EmailMaintenanceResponse;
  maintenanceLoading: Loading;
  maintenanceLog: MaintenanceModeLog[];
  maintenanceLogLoading: Loading;
  maintenanceMessageLoading: Loading;
  maintenanceModalVisibility: boolean;
  maintenanceMode: FeatureSetting;
  maintenanceModeLoading: Loading;
};

const initialState: MaintenanceState = {
  clearMaintenanceMessageLoading: "idle",
  emailLog: [],
  emailLogLoading: "idle",
  maintenanceEmailMessage: "",
  maintenanceEmailMessageLoading: "idle",
  maintenanceEmailMessageModalVisibility: false,
  maintenanceEmailMessageResponse: new EmailMaintenanceResponse(),
  maintenanceLoading: "idle",
  maintenanceLog: [],
  maintenanceLogLoading: "idle",
  maintenanceMessageLoading: "idle",
  maintenanceModalVisibility: false,
  maintenanceMode: new FeatureSetting(),
  maintenanceModeLoading: "idle",
};

export const fetchMaintenance = createAsyncThunk(
  "maintenance/fetchMaintenance",
  async (_, thunkAPI) => {
    return await client
      .get(new GetMaintenance())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setMaintenanceMode = createAsyncThunk(
  "maintenance/changeMaintenance",
  async (setValueTo: ChangeMaintenance, thunkAPI) => {
    return await client
      .put(new ChangeMaintenance(setValueTo))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setEmailMaintenanceMessage = createAsyncThunk(
  "maintenance/setEmailMaintenanceMessage",
  async (_, thunkAPI) => {
    const {
      maintenanceState: { maintenanceEmailMessage },
    } = thunkAPI.getState() as RootState;

    const args = new EmailMaintenanceMessage();
    args.message = maintenanceEmailMessage;

    return await client
      .post(new EmailMaintenanceMessage(args))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setMaintenanceMessage = createAsyncThunk(
  "maintenance/setMaintenanceMessage",
  async ({ message, expires }: SetMaintenanceMessage, thunkAPI) => {
    return await client
      .post(new SetMaintenanceMessage({ message, expires }))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const clearMaintenanceMessage = createAsyncThunk(
  "maintenance/clearMaintenanceMessage",
  async (_, thunkAPI) => {
    return await client
      .delete(new ClearMaintenanceMessage())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchEmailLog = createAsyncThunk(
  "maintenance/fetchEmailLog",
  async (_, thunkAPI) => {
    return await client
      .get(new GetEmailLog())
      .then((data) => {
        return data.log;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchMaintenanceLog = createAsyncThunk(
  "maintenance/fetchMaintenanceLog",
  async (_, thunkAPI) => {
    return await client
      .get(new GetMaintenanceLog())
      .then((data) => {
        return data.log;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const maintenanceModeSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setMaintenanceModalVisibility(state, action: PayloadAction<boolean>) {
      state.maintenanceModalVisibility = action.payload as boolean;
    },
    setMaintenanceEmailMessageModalVisibility(
      state,
      action: PayloadAction<boolean>
    ) {
      state.maintenanceEmailMessageModalVisibility = action.payload as boolean;
    },
    setMaintenanceEmailMessage(state, action: PayloadAction<string>) {
      state.maintenanceEmailMessage = action.payload as string;
    },
  },
  extraReducers: (builder) => {
    builder
      // GET: getMaintenance
      .addCase(fetchMaintenance.pending, (state) => {
        state.maintenanceLoading = "pending";
      })
      .addCase(fetchMaintenance.fulfilled, (state, action) => {
        state.maintenanceMode = action.payload as FeatureSetting;
        state.maintenanceLoading = "succeeded";
      })
      .addCase(fetchMaintenance.rejected, (state) => {
        state.maintenanceLoading = "failed";
        state.maintenanceMode = new FeatureSetting();
      })
      // PUT: changeMaintenance
      .addCase(setMaintenanceMode.pending, (state) => {
        state.maintenanceModeLoading = "pending";
      })
      .addCase(setMaintenanceMode.fulfilled, (state, action) => {
        state.maintenanceMode = action.payload as FeatureSetting;
        state.maintenanceModeLoading = "succeeded";

        state.maintenanceModalVisibility = false;
      })
      .addCase(setMaintenanceMode.rejected, (state) => {
        state.maintenanceModeLoading = "failed";
        state.maintenanceMode = new FeatureSetting();
      })
      // POST: setEmailMaintenanceMessage
      .addCase(setEmailMaintenanceMessage.pending, (state) => {
        state.maintenanceEmailMessageLoading = "pending";
      })
      .addCase(setEmailMaintenanceMessage.fulfilled, (state, action) => {
        state.maintenanceEmailMessageLoading = "succeeded";
        state.maintenanceEmailMessageResponse =
          action.payload as EmailMaintenanceResponse;
      })
      .addCase(setEmailMaintenanceMessage.rejected, (state) => {
        state.maintenanceEmailMessageLoading = "failed";
      })
      // POST: setMaintenanceMessage
      .addCase(setMaintenanceMessage.pending, (state) => {
        state.maintenanceMessageLoading = "pending";
      })
      .addCase(setMaintenanceMessage.fulfilled, (state) => {
        state.maintenanceMessageLoading = "succeeded";
      })
      .addCase(setMaintenanceMessage.rejected, (state) => {
        state.maintenanceMessageLoading = "failed";
      })
      // DELETE: clearMaintenanceMessage
      .addCase(clearMaintenanceMessage.pending, (state) => {
        state.clearMaintenanceMessageLoading = "pending";
      })
      .addCase(clearMaintenanceMessage.fulfilled, (state) => {
        state.clearMaintenanceMessageLoading = "succeeded";
      })
      .addCase(clearMaintenanceMessage.rejected, (state) => {
        state.clearMaintenanceMessageLoading = "failed";
      })
      // GET: fetchEmailLog
      .addCase(fetchEmailLog.pending, (state) => {
        state.emailLogLoading = "pending";
      })
      .addCase(fetchEmailLog.fulfilled, (state, action) => {
        state.emailLog = action.payload;
        state.emailLogLoading = "succeeded";
      })
      .addCase(fetchEmailLog.rejected, (state) => {
        state.emailLogLoading = "failed";
      })
      // GET: fetchMaintenanceLog
      .addCase(fetchMaintenanceLog.pending, (state) => {
        state.maintenanceLogLoading = "pending";
      })
      .addCase(fetchMaintenanceLog.fulfilled, (state, action) => {
        state.maintenanceLog = action.payload;
        state.maintenanceLogLoading = "succeeded";
      })
      .addCase(fetchMaintenanceLog.rejected, (state) => {
        state.maintenanceLogLoading = "failed";
      });
  },
});

export const {
  setMaintenanceModalVisibility,
  setMaintenanceEmailMessageModalVisibility,
  setMaintenanceEmailMessage,
} = maintenanceModeSlice.actions;

export default maintenanceModeSlice.reducer;

import {
  setMaintenanceEmailMessage,
  setMaintenanceEmailMessageModalVisibility,
} from "./maintenanceSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";
import { useEffect, useState } from "react";

import { getMaintenanceEmailMessage } from "./maintenance";

const MaintenanceEmailMessage = () => {
  const dispatch = useAppDispatch();
  const [localMaintenanceEmailMessage, setLocalMaintenanceEmailMessage] =
    useState("");

  const reduxMaintenanceEmailMessage = useAppSelector(
    getMaintenanceEmailMessage
  );

  const [defaultMessageToggle, setDefaultMessageToggle] =
    useState<boolean>(false);

  const defaultMessage =
    "We will be performing a major update today. We will be as quick as possible but the service may be unavailable from 14:00 to 15:00 UTC. Thank you for your understanding.";

  useEffect(() => {
    if (reduxMaintenanceEmailMessage === "") {
      setLocalMaintenanceEmailMessage(reduxMaintenanceEmailMessage);
    }
  }, [reduxMaintenanceEmailMessage]);

  useEffect(() => {
    if (defaultMessageToggle) {
      setLocalMaintenanceEmailMessage(defaultMessage);
    }
  }, [defaultMessageToggle]);

  useEffect(() => {
    if (localMaintenanceEmailMessage !== defaultMessage) {
      setDefaultMessageToggle(false);
    } else {
      setDefaultMessageToggle(true);
    }
  }, [localMaintenanceEmailMessage]);

  function handleEmailMessageModal(): void {
    dispatch(setMaintenanceEmailMessage(localMaintenanceEmailMessage));
    dispatch(setMaintenanceEmailMessageModalVisibility(true));
  }

  return (
    <div className="mb-3">
      <label className="form-label">Maintenance Email Message</label>
      <div className="d-flex">
        <div className="flex-fill">
          <textarea
            value={localMaintenanceEmailMessage}
            className="form-control form-control-sm"
            rows={3}
            placeholder="Enter maintenance email message"
            onChange={(e) => {
              setLocalMaintenanceEmailMessage(e.currentTarget.value);
            }}
          />
        </div>
        <div className=" ms-3 d-flex flex-column justify-content-between">
          <button
            className="btn btn-sm btn-secondary w-100"
            onClick={() => {
              setLocalMaintenanceEmailMessage("");
            }}
          >
            Clear
          </button>
          <button
            disabled={localMaintenanceEmailMessage === ""}
            id="button-maintenanceEmailMessage"
            className="btn btn-sm btn-outline-primary w-100"
            onClick={handleEmailMessageModal}
          >
            Send
          </button>
        </div>
      </div>

      <button
        className={`btn btn-sm d-flex py-0 btn-outline-primary mt-3 ${
          defaultMessageToggle ? "active" : ""
        }`}
        onClick={() => {
          setDefaultMessageToggle((t) => !t);
        }}
      >
        <small>Default Message</small>
      </button>
    </div>
  );
};

export default MaintenanceEmailMessage;

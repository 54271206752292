import { ErrorTypes } from "../service/api.dtos";
import { getNestFailures } from "../store/nestFailures";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FailuresList() {
  const usersFailuresData = useSelector(getNestFailures);
  const history = useHistory();
  function rowClick(id: string) {
    return history.push({
      pathname: "/failures",
      search: `?id=${id}`,
    });
  }

  return (
    <div className=" d-flex flex-grow-1 ">
      {/* Users Failures */}
      <div className="table-responsive w-100 theme-overlay">
        <table
          id="table-failuresList"
          className="table table-dark table-hover mb-0  small"
        >
          <thead className="sticky-top">
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Requested Time</th>
              <th scope="col">Issue</th>
              <th scope="col">Resolution</th>
            </tr>
          </thead>
          <tbody>
            {usersFailuresData?.failures &&
              Array.from(
                usersFailuresData.failures.map((userFailureData) => (
                  // {/* Name */}
                  <tr
                    className="theme-pointer text-nowrap"
                    key={userFailureData.id}
                    onClick={() => rowClick(userFailureData.id)}
                  >
                    {/* Type */}
                    <th scope="row">
                      {userFailureData.typeOfError
                        .toString()
                        .split(/(?=[A-Z])/)
                        .join(" ")}
                    </th>
                    {/* Requested Time */}
                    <td className="text-nowrap">
                      {new Date(userFailureData.occurred).toLocaleString(
                        "en-GB"
                      )}
                    </td>
                    {/* Issue */}
                    <td>
                      {userFailureData.typeOfError.toString() ===
                      ErrorTypes[ErrorTypes.UserDeletions] ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: userFailureData.reason,
                          }}
                        />
                      ) : (
                        <div className="theme-white-space">
                          {userFailureData.reason}
                        </div>
                      )}
                    </td>
                    {/* Resolution */}
                    <td className="text-nowrap">
                      {userFailureData.signupResolution}
                    </td>
                  </tr>
                ))
              ).reverse()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
